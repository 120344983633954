<template>
  <div>
    <calc-result-page>
      <div class="center">
        <div class="title-box">
          <img src="@/assets/imgs/calc/bias.png" />合计应缴：
        </div>
        <div class="money-box">{{ res.amount }}<span>元</span></div>

        <div class="content-box">
          <div>
            单位应缴：<span>{{ res.companyFee }}</span
            >元
          </div>
          <div>
            个人应缴：<span>{{ res.personFee }}</span
            >元
          </div>
        </div>
        <div
          class="details-box"
          @click="onDetail"
        >
          查看明细
        </div>

        <div class="button-box">以上结果只是测算，最终以社保局计算结果为准</div>
      </div>
    </calc-result-page>
  </div>
</template>

<script>
import CalcResultPage from "@/components/CalcResultPage";
import { Divider } from "vant";
export default {
  components: {
    CalcResultPage,
    [Divider.name]: Divider,
  },
  mounted() {
    const res = this.$route.query.res;
    if (res != undefined) {
      this.resStr = res;
      this.res = JSON.parse(this.$route.query.res);
    }
  },
  data() {
    return {
      resStr: "",
      res: {},
    };
  },
  methods: {
    onDetail() {
      this.$router.push({
        name: "PensionSupplementResultDetails",
        query: { res: this.resStr },
      });
    },
  },
};
</script>

<style scoped>
.center {
  padding: 0 14px;
}

.money-box {
  font-size: 44px;
  font-weight: bold;
  line-height: 54px;
  color: #658CF1;
}

.money-box span {
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: #999999;
}

.title-box {
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;
  color: #666666;
  display: flex;
  align-items: center;
}

.title-box img {
  width: 8px;
  height: 18px;
  margin-right: 2px;
}

.center >>> .van-divider {
  margin: 0;
}

.button-box {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #658CF1;
  margin: 50px 0 0 16px;
}

.content-box {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  color: #666666;
  margin: 46px 0 16px 0;
}

.content-box span {
  font-size: 30px;
  font-weight: bold;
  line-height: 36px;
  color: #333333;
}

.details-box {
  width: 95px;
  padding: 4px 12px;
  border: 1px solid #658CF1;
  opacity: 1;
  border-radius: 0px 15px 15px 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #658CF1;
}
</style>